@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: openSans-Regular;
    src: url(../src/fonts/open-sans/OpenSans-Regular.ttf);
}
@font-face {
    font-family: openSans-Bold;
    src: url(../src/fonts/open-sans/OpenSans-Bold.ttf);
}
@font-face {
    font-family: openSans-extraBold;
    src: url(../src/fonts/open-sans/OpenSans-ExtraBold.ttf);
}
@font-face {
    font-family: openSans-light;
    src: url(../src/fonts/open-sans/OpenSans-Light.ttf);
}